<template>
  <footer>
    <p>Footer</p>
  </footer>
</template>

<script lang="ts" setup>
const { $texts } = useNuxtApp()
const copyright = computed(() => {
  const year = new Date().getFullYear().toString()
  return $texts('footer.copyright', '@year Liip AG').replace('@year', year)
})
</script>

<style lang="postcss">
.page-footer-grid {
  grid-template:
    'logo top' theme(space.10)
    'copyright local' 1fr
    'global global' auto
    'search context' auto
    / 1fr auto;

  @screen md {
    grid-template:
      'logo copyright language top' 50px
      / auto 1fr auto auto;
  }
}

.grid-area-logo {
  grid-area: logo;
}

.grid-area-copyright {
  grid-area: copyright;
}

.grid-area-switch {
  grid-area: switch;
}

.grid-area-top {
  grid-area: top;
}

.grid-area-search {
  grid-area: search;
}
</style>
