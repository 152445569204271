<template>
  <div>
    <PageHeader @menu:open="menuOpen" @menu:close:start="menuCloseStart" @menu:close:finished="menuCloseFinish" />

    <div :class="{
      'pt-[100px]':
        (isMenuOpen || !hasMenuFinishedClosing) && viewport.isLessThan('lg'),
    }">

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>

        <NuxtPage />
      </div>
    </div>
    <PageFooter />
  </div>


  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">

    <symbol id="brandshape-vertical" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M208,0h-41.6v208h41.6V0Z" />
      <path style="fill: var(--color-svg-main);" d="M41.6,0H0v208h41.6V0Z" />
      <path style="fill: var(--color-svg-accent);" d="M124.8,0h-41.6v208h41.6V0Z" />
    </symbol>

    <symbol id="brandshape-horizontal" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M208,0H0v41.6h208V0Z" />
      <path style="fill: var(--color-svg-accent);" d="M208,83.2H0v41.6h208v-41.6Z" />
      <path style="fill: var(--color-svg-main);" d="M208,166.4H0v41.6h208v-41.6Z" />
    </symbol>

    <symbol id="brandshape-vertical" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M60.9,0h-.4l-.4.8L0,60.9v58.8L78.2,41.6h129.8V0H60.9Z" />
      <path style="fill: var(--color-svg-accent);" d="M95.4,83.2L0,178.6v29.4h29.4l83.2-83.2h95.4v-41.6h-112.6Z" />
      <path style="fill: var(--color-svg-main);" d="M147.1,208h60.9v-41.6h-78.2l-41.6,41.6h58.8Z" />
    </symbol>

    <symbol id="brandshape-diagonal" viewBox="0 0 208 208">
      <polygon style="fill: var(--color-svg-main);" points="208 88.2 208 147.1 147.1 208 88.2 208 208 88.2" />
      <polygon style="fill: var(--color-svg-main);" points="119.7 0 0 119.7 0 60.9 60.9 0 119.7 0" />
      <polygon style="fill: var(--color-svg-accent);" points="208 0 208 29.4 29.4 208 0 208 0 178.6 178.6 0 208 0" />
    </symbol>

    <symbol id="brandshape-slant" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M60.9,0h-.4l-.4.8L0,60.9v58.8L78.2,41.6h129.8V0H60.9Z" />
      <path style="fill: var(--color-svg-accent);" d="M95.4,83.2L0,178.6v29.4h29.4l83.2-83.2h95.4v-41.6h-112.6Z" />
      <path style="fill: var(--color-svg-main);" d="M147.1,208h60.9v-41.6h-78.2l-41.6,41.6h58.8Z" />
    </symbol>

    <symbol id="brandshape-arrow-right" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M0,138.6l34.7-34.7L0,69.1v69.4Z" />
      <path style="fill: var(--color-svg-accent);" d="M69.4,208l104.1-104.2L69.7,0H.3l103.8,103.8L0,208h69.4Z" />
      <path style="fill: var(--color-svg-main);" d="M208,0h-68.8l68.8,68.8V0Z" />
      <path style="fill: var(--color-svg-main);" d="M208,138.9l-69.1,69.1h69.1v-69.1Z" />
    </symbol>

    <symbol id="brandshape-arrow-up" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M138.6,208l-34.7-34.7-34.7,34.7h69.4Z" />
      <path style="fill: var(--color-svg-accent);"
        d="M208,138.6L103.8,34.4,0,138.2v69.4l103.8-103.8,104.2,104.2v-69.4Z" />
      <path style="fill: var(--color-svg-main);" d="M0,0v68.8L68.8,0H0Z" />
      <path style="fill: var(--color-svg-main);" d="M138.9,0l69.1,69.1V0h-69.1Z" />
    </symbol>

    <symbol id="brandshape-arrow-down" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M69.4,0l34.7,34.7L138.8,0H69.4Z" />
      <path style="fill: var(--color-svg-accent);" d="M0,69.4l104.2,104.2L208,69.6V0L104.2,104.2,0,0v69.4Z" />
      <path style="fill: var(--color-svg-main);" d="M208,208v-68.8L139.2,208H208Z" />
      <path style="fill: var(--color-svg-main);" d="M69.1,208L0,138.9V208h69.1Z" />
    </symbol>

    <symbol id="brandshape-angle-up" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M41.6,166.4H0v41.6h41.6v-41.6Z" />
      <path style="fill: var(--color-svg-main);" d="M0,0v41.6h166.4v166.4h41.6V0H0Z" />
      <path style="fill: var(--color-svg-accent);" d="M0,83.2v41.6h83.2v83.2h41.6v-124.8H0Z" />
    </symbol>

    <symbol id="brandshape-angle-down" viewBox="0 0 208 208">
      <path style="fill: var(--color-svg-main);" d="M208,0h-41.6v41.6h41.6V0Z" />
      <path style="fill: var(--color-svg-main);" d="M208,208v-41.6H41.6V0H0v208h208Z" />
      <path style="fill: var(--color-svg-accent);" d="M208,124.8v-41.6h-83.2V0h-41.6v124.8h124.8Z" />
    </symbol>

  </svg>


</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()
const viewport = useViewport()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const showBreadcrumb = computed(() => !route.meta.hideBreadcrumb)

useHead({
  htmlAttrs: {
    lang: language.value,
  },
})

const isMenuOpen = ref(false)
const hasMenuFinishedClosing = ref(true)

function menuOpen() {
  window.document.body.classList.add('overflow-y-hidden')
  isMenuOpen.value = true
}

function menuCloseStart() {
  if (viewport.isLessThan('lg')) {
    isMenuOpen.value = false
    hasMenuFinishedClosing.value = false
  }
}

function menuCloseFinish() {
  if (viewport.isLessThan('lg')) {
    window.document.body.classList.remove('overflow-y-hidden')
    hasMenuFinishedClosing.value = true
  }
}
</script>
